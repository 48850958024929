:root {

  --bg:#23213e;
  --background: rgba(35, 33, 62, .75);
  --text: #ffffff;
  --emphasis: #FFB400;
    cursor: url(./images/cursor.svg), auto;
}

* {
  box-sizing: border-box;
  cursor: url(./images/cursor.svg), auto;
}

body { 
  background-image: url('images/bg.svg');
  cursor: url(./images/cursor.svg), auto;
}

span{
  border-radius: 0px;
}




#header{
    font-family: 'Abril Fatface', serif;
        background: -webkit-linear-gradient(180deg,
      #FFB400 0%,
      #FFDB95 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  font-size: 4rem;
  font-weight: bolder;
  z-index: 999;
  letter-spacing: 10px;
  line-height: 5rem;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 0px;
}


#mobileDiv{
  float: right;
  padding: 20px;
  margin-left: 20px;
}




h2 {
  
  font-family: 'Abril Fatface', serif;
  text-transform: lowercase;
  font-weight: bolder;
  background-color: #FFB400;
  color: var(--bg);
      font-size: 2rem;
  padding: 10px;
  border-radius: 10px;
  letter-spacing: .1rem;


}

h3{
  font-family: 'Abril Fatface', serif;
  letter-spacing: .1rem;
  font-size: 1.5rem;
}



#skills { 
  width: 75px; 
  height: 75px; 
  background-color: var(--emphasis);
  border-radius: 5px;
  margin: 5px; 
  padding: 10px; 
}


.nav {

  background-color: transparent;
  width: 5vw; 
  display: flex;
    writing-mode: vertical-lr;
      margin-right: 50px;
  position: fixed;
  top: 50%; 
  left: 0px;
  overflow: auto;
  align-content: center;
  transform: translateY(-50%);   

}


#imageBackground{
  background-image: linear-gradient(180deg,
  rgba(35, 33, 62, .6) 0%,
  rgba(35, 33, 62, 0.2) 100%);
  color: #ffffff; 
  font-size: .5rem;
  font-family: 'Onest', sans-serif;  font-weight: 1000;
  border-radius: 0px;

}

.navStyle { 
  border: 0px; 
  background-color: transparent;
      font-family: 'Onest', sans-serif;  font-weight: 1000;
  font-size: 16px;
  color: var(--text); 
  text-orientation: mixed;
  transform: scale(-1, -1);
  letter-spacing: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
  margin-right: 20px;


}


.nav  a,
.nav a:visited{
  border: 0px;
    background-color: transparent;
    font-family: 'Onest', sans-serif;
    font-weight: 1000;
    font-size: 18px;
    color: var(--text);
    text-orientation: mixed;
    transform: scale(-1, -1);
    letter-spacing: 5px;
    margin-bottom: 5px;
    text-transform: uppercase;
    margin-right: 20px;
   
}



.nav a:hover{
  animation: hoverlink 2s ease-in-out infinite alternate;
  background: -webkit-linear-gradient(90deg,
        #FFB400 0%,
        #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bolder;
}

@keyframes hoverlink {
  
    0% {
        background-position: right;
      }
    
      50% {
        background-position: left;
      }
    
      100% {
        background-position: right;
      }
  
  
}



.content{
  width: 90%;
  position: absolute; 
  top: 0px; 
  left: 10%;
  
}

.written{
      background-color: var(--background);
      width: inherit; 
      z-index: 1;
      box-shadow: 0px 3px 6px #242423;
      border-radius: 25px;
      opacity: 1;
      font-family: 'Onest', sans-serif;
      color: var(--text);
      font-size: 16px;
      font-weight: 400;
      z-index: 998;
      padding: 20px;
      margin: 50px;



    }


.written a,
.written a:visited,
.written a:active {
  font-family: 'Onest', sans-serif;
  background: -webkit-linear-gradient(90deg,
      #FFB400 0%,
      #FFDB95 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
  text-decoration: none;
}

.written a:hover{
  animation: hoverlink2 2s linear infinite alternate;
  

}

.written a:hover::after{
  color:#ffffff;
}

@keyframes hoverlink2 {

  0% {
    background: -webkit-linear-gradient(180deg,
          #FFB400 0%,
          #FFDB95 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }

  100% {
    background: -webkit-linear-gradient(90deg,
          #FFB400 100%,
            #FFDB95 0%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }

  


}

#mobileHide{
  display: inline;

}


#accordian a, 
#accordian a:visited
{
  color: var(--background);
  text-decoration: underline;
}


 @media only screen and (max-width: 500px) {

  .content {
      width: 90vw;
      position: absolute;
      top: 0px;
      left: 0px; 

      margin: 10px;

      padding: 0px; 
  
    }

    .nav{
      display: none;

    }

    
 
  

        .written {
          background-color: var(--background);
          width: 100%;
          z-index: 1;
          box-shadow: 0px 3px 6px #242423;
          border-radius: 25px;
          opacity: 1;
          font-family: 'Onest', sans-serif;
          color: var(--text);
          font-size: 16px;
          font-weight: 400;
          z-index: 998;
          padding: 10px;
          margin: 10px;
          padding: 5px;
    
        }

      #mobileHide{
        display: none;
      }


      #header{
        font-family: 'Abril Fatface', serif;
            background: -webkit-linear-gradient(180deg,
          #FFB400 0%,
          #FFDB95 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      font-size: 2rem;
      font-weight: bolder;
      z-index: 999;
      letter-spacing: 10px;
      line-height: 2rem;
      text-align: left;
      width: 100%;
      margin-bottom: 10px;
      margin-top: 0px;
    }
    
    #mobileDiv{
      float: right;
      padding: 0px;
      margin-left: 0px;
    }
    
    
    
    
    
    
    h2 {
      
      font-family: 'Abril Fatface', serif;
      text-transform: lowercase;
      font-weight: bolder;
      background-color: #FFB400;
      color: var(--bg);
          font-size: 2rem;
      padding: 10px;
      border-radius: 10px;
      letter-spacing: .1rem;
    
    
    }
    
    h3{
      font-family: 'Abril Fatface', serif;
      letter-spacing: .1rem;
      font-size: 2rem;
    }
    
}